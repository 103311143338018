import React from "react";
import "./css/mainpage.css";
import Spotlight from "./Spotlight";

const Home = () => {

    return (
        <>
            <Spotlight />
        </>
    );
};

export default Home;

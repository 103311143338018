import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import { DonationTableConfig } from "../Configs/Config";
import useWachDonationDetails from './Hooks/getDonarDetails';

const DonarDetails = () => {
    const [donarDetails, setDonarDetails] = useState();
    const [filteredData, setFilteredData] = useState(donarDetails?.donationDetailsList);
    const [searchedColumn, setSearchedColumn] = useState('');

    //to get donar details
    const { mutate: mutateGetWachDonationDetails, isLoading, data: successDonarDetails } = useWachDonationDetails();

    useEffect(() => {
        mutateGetWachDonationDetails();
    }, [])
    useEffect(() => {
        if (successDonarDetails) {
            setDonarDetails(successDonarDetails);
        }
    }, [successDonarDetails])

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchedColumn(dataIndex);
        const filtered = donarDetails?.donationDetailsList?.filter(item =>
            item[dataIndex]?.toString()?.toLowerCase()?.includes(selectedKeys[0].toLowerCase())
        );
        setFilteredData(filtered);
    };
    useEffect(() => {
        setFilteredData(donarDetails?.donationDetailsList)
    }, [donarDetails?.donationDetailsList])

    const handleReset = (clearFilters, dataIndex, setSelectedKeys, selectedKeys, confirm,) => {
        clearFilters();
        setSearchedColumn('');
        setSelectedKeys([]);
        setFilteredData(donarDetails?.donationDetailsList);
        handleSearch(selectedKeys, confirm, dataIndex)
        mutateGetWachDonationDetails();
    };
    const IconComponent = () => {
        const handleIconClick = () => {
            console.log('Icon clicked!');
        };

        return (
            <ProfileOutlined size='large' onClick={handleIconClick} />
        );
    };

    const renderCell = (text, type) => {
        switch (type) {

            case 'Icon':
                return <IconComponent />;
            default:
                return text;
        }
    };

    const getColumnSearchProps = (dataIndex, type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                {selectedKeys.length > 0 && (
                    <>
                        <Button
                            type="link"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                            disabled={selectedKeys.length === 0}
                        >
                            Search
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => handleReset(clearFilters, dataIndex, setSelectedKeys, selectedKeys, confirm)}
                        >
                            Reset
                        </Button>
                    </>
                )}
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => document.querySelector('.ant-table-filter-dropdown input')?.select(), 100);
            }
        },
        render: (text) => {
            const renderedText = renderCell(text, type);
            return searchedColumn === dataIndex ? (
                <span>{renderedText}</span>
            ) : (
                renderedText
            );
        },
    });

    const columns = DonationTableConfig.Columns.map(column => ({
        ...column,
        ...getColumnSearchProps(column.dataIndex, column.type, column.align),
        align: column?.align
    }));

    return (
        <Table
            columns={columns}
            dataSource={filteredData}
            rowKey={(record, index) => index}
            loading={isLoading}
            style={{ width: '100%' }}
            pagination={{
                total: donarDetails?.donationDetailsList?.length,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total} items`,
            }}
        />
    );
}

export default DonarDetails;

import React from 'react';
import { Carousel } from 'react-bootstrap';
import './css/mainpage.css';
import { HomeSliderImages } from '../../Configs/Config';

function Spotlight() {
    return (
        <>
            {
                HomeSliderImages ?
                    <Carousel>
                        {
                            HomeSliderImages.map((img, key) => {
                                return (

                                    <Carousel.Item interval={3000} key={key}>
                                        <img
                                            className="d-block w-100"
                                            src={img}
                                            alt="First slide"
                                        />
                                    </Carousel.Item>

                                );
                            })
                        }
                    </Carousel> : null
            }
        </>
    );
}

export default Spotlight
import { Card, Divider, Row, Col, Button } from 'antd';
import './AdminSidebar.css';
import abc from './../../../img/abc.png'

const AdminSidebar = ({ loggedinUserDetails, setSelectedCard }) => {
  return (
    <div className="admin-home-sidebar">
      <Card
        bordered={false}
        hoverable
        className="admin-card"
      >
        <div className='admin-info-container'>
          <span>Admin Details</span>
          <div className="admin-avatar-container">
            {/* Profile Picture */}
            <img
              src={`https://wach-gallery.s3.ap-south-1.amazonaws.com/admin-team-images/${loggedinUserDetails?.adminInformation?.username}_DP.jpg`}
              alt="Profile"
              className="admin-avatar"
              onError={(e) => {
                e.target.onerror = null;  
                e.target.src = abc;      
              }}
            />
          </div>
        </div>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div>
              <span className="label">Name:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.name}</span>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <span className="label">Email:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.emailId}</span>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <span className="label">Phone:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.mobileNumber}</span>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <span className="label">Role:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.role}</span>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <span className="label">Username:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.username}</span>
            </div>
          </Col>
          <Col span={24}>
            <div>
              <span className="label">Status:</span>
              <span className="value">{loggedinUserDetails?.adminInformation?.status}</span>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={8} className='footer-icons'>
          <Button type="Primary" className="custom-button" onClick={() => setSelectedCard(null)}>Back to Admin Home</Button>
        </Row>
      </Card>
    </div>
  );
};

export default AdminSidebar;

import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import "./AdminPaymentAprove.css";

const { TextArea } = Input;

const AdminPaymentAprove = ({ pendingPaymentRequestsData, loggedinUserDetails, getPendingPaymentRequests }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionType, setActionType] = useState(""); // 'approve' or 'reject'
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const users = pendingPaymentRequestsData?.pendingPaymentRequestsList;

  const handleActionClick = (user, action) => {
    setSelectedUser(user);
    setActionType(action);
    setIsModalVisible(true);
    setReason("");
  };

  const handleOk = async () => {
    try {
      setIsLoading(true)
      if (!reason.trim()) {
        message.error("Please provide a reason for the action.");
        return;
      }
      const response = await fetch("https://z6qeyg2lo2.execute-api.ap-south-1.amazonaws.com/dev/wachDonationDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          functionName: "approveRejectPaymentRequests",
          userId: selectedUser?.userId,
          approvedBy: loggedinUserDetails?.adminInformation?.username,
          approvalRemarks: reason,
          isPaymentSuccess: actionType === "approve" ? "Y" : "N"
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      message.success("Payment request processed successfully");
      await getPendingPaymentRequests();
    } catch (error) {
      message.error("An error occurred while processing the request.");
    } finally {
      // Clean up state regardless of success or failure
      setIsModalVisible(false);
      setSelectedUser(null);
      setReason("");
      setActionType("");
      setIsLoading(false);
    }
  };


  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
    setReason("");
    setActionType("");
  };
  const getRandomColor = () => {
    const colors = [
      '#FFE5E5', 
      '#E5E5FF',
      '#F0F8FF', // AliceBlue
      '#F8F0E3', // Antique white
      '#FFE4E1', // Misty rose
      '#E6E6FA', // Lavender
      '#F0FFF0', // Honeydew
      '#F5F5DC', // Beige
      '#FFF0F5', // LavenderBlush
      '#E0FFFF', // LightCyan
      '#FAFAD2', // LightGoldenrod
      '#F0FFFF', // Azure
      '#F5FFFA', // MintCream
      '#FDF5E6', // OldLace
      '#FAF0E6', // Linen
      '#FFF5EE', // Seashell
      '#F0F0F0', // Light gray
      '#EEE8AA', // PaleGoldenrod
      '#E6E8FA', // Light periwinkle
      '#FFE4B5', // Moccasin
      '#FFE5B4', // Peach
      '#E8F4F8'  // Light sky blue
    ]; // Pastel colors for better readability
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="container">
      <h1 className="header">Payment Request Approval Panel</h1>
      <div className="tilesContainer">
        {users?.length === 0 && <div className="noData"> Pending Payments are not available</div>}
        {users?.map((user, index) => (
          <div
            key={index}
            className="tile"
            style={{ backgroundColor: getRandomColor() }}
            onMouseEnter={(e) => e.currentTarget.classList.add("tileHover")}
            onMouseLeave={(e) => e.currentTarget.classList.remove("tileHover")}
          >
            <div className="userDetails">
              <div className="name">{user?.name}</div>
              <div className="email">{user?.emailId}</div>
              <div className="phone">{user?.mobileNumber}</div>
              <div className="amount">Rs.{user?.amount}</div>
              <div className="date">{user?.donationDate}</div>
              <div className="comment">{user?.comments}</div>
            </div>
            <div className="actions">
              <button
                className="approveButton"
                onClick={() => handleActionClick(user, 'approve')}
              >
                Approve
              </button>
              <button
                className="rejectButton"
                onClick={() => handleActionClick(user, 'reject')}
              >
                Reject
              </button>
            </div>
          </div>
        ))}
      </div>

      <Modal
        title={`${actionType === 'approve' ? 'Approve' : 'Reject'} Payment`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        loading={isLoading}
        okText={actionType === 'approve' ? 'Approve' : 'Reject'}
        cancelText="cancel"
        cancelButtonProps={{
          style: {
            // Add your CSS properties here
            borderRadius: '4px',
            fontWeight: '600',
            padding: '0 20px',
            height: '30px',
            backgroundColor: '#595959',
            color: '#ffffff',
            border: 'none'
          }
        }}
        okButtonProps={{
          //type: actionType === 'approve' ? 'primary' : 'danger',
          style: {
            // Add your CSS properties here
            borderRadius: '4px',
            fontWeight: '600',
            padding: '0 20px',
            height: '30px',
            // You can conditionally apply styles based on actionType
            backgroundColor: actionType === 'approve' ? '#28a745' : '#dc3545',
            border: 'none'
          }
        }}
      >
        <TextArea
          placeholder="Enter reason..."
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={4}
        />
      </Modal>
    </div>
  );
};

export default AdminPaymentAprove;

import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Spin, Upload } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import MutateaddNewDonationDetails from '../Hooks/addNewDonation';
import { DonationFormConfig } from '../../Configs/Config';
import '../Mainpage/css/donationform.css';
import Payment_QR from './../../img/Payment_QR.jpg';

const DonationForm = () => {
    const [form] = Form.useForm();
    const [amount, setAmount] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showupload, setshowupload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');


    const handleAmountChange = (value) => {
        setAmount(value);
    };

    const { 
        mutate: mutateAddDonation,
        isLoading: isAddDonationLoading,
        data: addDonationData,
        isError: isAddDonationError,
        isSuccess: isAddDonationSuccess,
    } = MutateaddNewDonationDetails();

    const handleSubmit = (values) => {
        setHasSubmitted(true);
        const fullName = `${values.firstName} ${values.lastName}`;
        const formData = { ...values, name: fullName };
        
        Object.keys(formData).forEach(key => {
            if (formData[key] === undefined) {
                formData[key] = '';
            } else {
                formData[key] = String(formData[key]);
            }
        });
        mutateAddDonation(formData);
    };

    const handleFileUpload = async () => {
        setWarningMessage('')
        if (fileList.length === 0) {
            message.error('Please select a file first');
            return;
        }
    
        const file = fileList[0].originFileObj;
        const validImageTypes = ['image/jpeg', 'image/jpg'];
        const maxSize = 2 * 1024 * 1024; // 2MB max size
    
        // Validate file type
        if (!validImageTypes.includes(file.type)) {
            setWarningMessage('Please upload only JPG/JPEG files')
            return;
        }
    
        // Validate file size
        if (file.size > maxSize) {
            setWarningMessage('File size should be less than 2MB')
            return;
        }
    
        setUploadLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        
        try {
            const response = await fetch('your-upload-api-endpoint', {
                method: 'POST',
                body: formData,
            });
    
            const result = await response.json();
    
            if (response.ok) {
                message.success('Payment proof uploaded successfully');
                setFileList([]);
                handleReset();
            } else {
                throw new Error(result.message || 'Upload failed');
            }
        } catch (error) {
            message.error('Failed to upload payment proof: ' + error.message);
        } finally {
            setUploadLoading(false);
        }
    };
    

    useEffect(() => {
        if (!hasSubmitted) return;
        if (isAddDonationSuccess && addDonationData?.statusCode === "1" && !isAddDonationError) {
            setIsModalVisible(true);
            setshowupload(true);
        }
        if (isAddDonationError || addDonationData?.statusCode !== "1") {
            handleReset();
            message.error('Failed to Donate', 5);
        }
    }, [isAddDonationSuccess, isAddDonationError, addDonationData?.statusCode]);

    const handleReset = useCallback(() => {
        form.resetFields();
        setIsModalVisible(false);
        setAmount('');
        setFileList([]);
        setWarningMessage('')
    }, [form]);

    const handleDownloadQR = () => {

        fetch(Payment_QR )
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'donation-qr-code.jpg';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error downloading QR code:', error);
                message.error('Failed to download QR code');
            });
    };

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.error('You can only upload image files!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('Image must be smaller than 2MB!');
                return false;
            }
            return false; // Prevent auto upload
        },
        fileList,
        onChange: ({ fileList: newFileList }) => {
            setFileList(newFileList);
        },
        maxCount: 1,
    };

    const renderFormFields = () => {
        return DonationFormConfig.Fields.map((field) => {
            let inputComponent;
            switch (field.Type) {
                case 'Text':
                    inputComponent = <Input placeholder={field.PlaceHolder} prefix={<field.Icon />} />;
                    break;
                case 'Email':
                    inputComponent = <Input type="email" placeholder={field.PlaceHolder} prefix={<field.Icon />} />;
                    break;
                case 'TextArea':
                    inputComponent = <Input.TextArea placeholder={field.PlaceHolder} prefix={<field.Icon />} />;
                    break;
                case 'Number':
                    inputComponent = (
                        <InputNumber
                            className="custom-number-input"
                            placeholder={field.PlaceHolder}
                            style={{ width: '100%' }}
                            onChange={field.Field === 'amount' ? handleAmountChange : null}
                            prefix={<field.Icon />}
                            min={1}
                            step={1}
                            onKeyDown={(e) => {
                                const acceptedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
                                if (!/[0-9]/.test(e.key) && !acceptedKeys.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    );
                    break;
                default:
                    inputComponent = <Input placeholder={field.PlaceHolder} />;
            }

            if (field.Field === 'firstName' || field.Field === 'lastName') {
                return (
                    <Col span={12} key={field.Field}>
                        <Form.Item
                            name={field.Field}
                            label={field.Label}
                            rules={[{ required: field.Required, message: `${field.Label} is required` }]}
                        >
                            {inputComponent}
                        </Form.Item>
                    </Col>
                );
            }

            return (
                <Form.Item
                    key={field.Field}
                    name={field.Field}
                    label={field.Label}
                    rules={[{ required: field.Required, message: `${field.Label} is required` }]}
                >
                    {inputComponent}
                </Form.Item>
            );
        });
    };

    return (
        <Spin spinning={isAddDonationLoading} tip="Loading...">
            <div className="form-container animated-container" style={{ position: 'relative' }}>
                <div className="background-image"></div>
                <h2>Donation Form</h2>
                
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        {renderFormFields().filter(field => 
                            field.key === 'firstName' || field.key === 'lastName'
                        )}
                    </Row>
                    {renderFormFields().filter(field => 
                        field.key !== 'firstName' && field.key !== 'lastName'
                    )}
                    <Form.Item>
                        <Button type="default" onClick={handleReset} style={{ marginRight: '10px' }} disabled ={showupload}>
                            Reset
                        </Button>
                        <Button type="primary" htmlType="submit" disabled ={showupload}>
                            {amount ? `Donate ${amount} Now` : 'Donate Now'}
                        </Button>
                    </Form.Item>
                </Form>

                {showupload && (
                    <div className="upload-section">
                        <h3>Upload Payment Proof</h3>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                            <Button 
                                type="primary"
                                onClick={handleFileUpload}
                                loading={uploadLoading}
                                disabled={fileList.length === 0}
                            >
                                Upload Payment Proof
                            </Button>
                        </div>
                        {fileList.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                Selected file: {fileList[0].name}
                            </div>
                        )}
                        {warningMessage && (
                        <div className="warning-message">
                            <ExclamationCircleOutlined />
                            {warningMessage}
                        </div>
                    )}
                    </div>
                )}

                <Modal
                    title="Donation QR"
                    open={isModalVisible}
                    centered={true}
                    width={600}
                    onCancel={()=>setIsModalVisible(false)}
                    footer={
                        <div style={{ textAlign: 'center' }}>
                            <Button 
                                type="primary" 
                                onClick={handleDownloadQR}
                                style={{ marginRight: '10px' }}
                            >
                                Download QR Code
                            </Button>
                            <div style={{ marginTop: '10px' }}>
                                Please scan the Above QR code to donate your amount
                            </div>
                        </div>
                    }
                >
                    <div className="qr-container">
                        <img 
                            src={Payment_QR} 
                            alt="QR Code" 
                            className="qr-image" 
                            style={{ maxWidth: '100%', height: 'auto' }} 
                        />
                    </div>
                </Modal>
            </div>
        </Spin>
    );
};

export default DonationForm;

import React from "react";
import { Link } from "react-router-dom";
import "./css/mainpage.css";
import watchLogo from "./../../img/LogoIcon.png";
import AnnouncementBar from "./Utils/AnnouncementBar";

const Header = (props) => {


  return (
    <>
    {/* <div><AnnouncementBar/></div> */}
      <div className="header-bar">
        <div className="header-outer-wrapper">
          <div className="header-inner-wrapper">
            <div id="menuToggle">
              <input className="sidebar" type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <Link className="active" to="/">
                  <li>Home</li>
                </Link>
                <Link to="donardetails">
                  <li>Donations Received</li>
                </Link>
                <Link to="">
                  <li>Donations Made</li>
                </Link>
                <Link to="">
                  <li>Gallery </li>
                </Link>
                <Link to="">
                  <li>About Us </li>
                </Link>
                <Link to="">
                  <li>Contact Us</li>
                </Link>
                <hr className="sideNav" />
                <Link to="Admin">
                  <li>Admin Login</li>
                </Link>
                <Link to="DonateNow">
                  <li>Donate Now</li>
                </Link>
              </ul>
            </div>
            <div className="logo-wrapper">
                <picture className="logo">
                  <img
                    srcSet={watchLogo}
                    alt="sky store"
                  />
                </picture>
            </div>
          </div>
          <div className="navbar-wrapper">
            <nav className="nav-bar">
              <ul className="parent dropwdownmenu">
                <li
                >
                  <span className="homelink">
                  <Link to="Home">
                  <button
                    className="dropbtn"
                    style={{
                      width: "160px"
                    }}
                  >
                    Home
                  </button>
                  </Link>
                  </span>
                </li>
                <li
                >
                   <Link to="donardetails">
                   <button
                    className="dropbtn"
                    style={{
                      width: "250px"
                    }}
                  >
                   Donations Received
                  </button>
                  </Link>
                </li>
                <li
                >
                  <button
                    className="dropbtn"
                    style={{
                      width: "160px"
                    }}
                  >
                  Donations Made
                  </button>
                </li>
                <li >
                  <button className="dropbtn"
                    style={{
                      width: "160px"
                    }}>
                  Gallery 
                  </button>
                </li>
                <li >
                  <button className="dropbtn"
                    style={{
                      width: "160px"
                    }}>
                    About Us
                  </button>
                </li>
                <li >
                  <button className="dropbtn"
                    style={{
                      width: "160px"
                    }}>
                    Contact Us
                  </button>
                </li>
                <li >
                <Link to="Admin">
                <button className="dropbtn "  style={{
                      width: "160px"
                    }}>
                     Admin Login
                    </button>
                    </Link>
                    </li>
                <li className="dropdown-item redeem">
                  <Link to="DonateNow">
                    <button className="dropbtn ">
                      Donate Now
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

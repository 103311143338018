import React from 'react';
import './css/mainpage.css';
import './css/footer.css';

function Footer() {
    return (
        <footer className="mainfooter">
            <div className="bottom-footer container">

                <nav className="toplink region">
                    <ul className="footernav regionnav">
                        <li className="footerlink regionlink">
                            <span> WE ALL CAN HELP </span>
                        </li>
                        <li className="footerlink regionlink">
    <a href="mailto:team.weallcanhelp@gmail.com?subject=query related to donation&body=Hello,@team WACH">team.weallcanhelp@gmail.com</a>
</li>

                    </ul>
                </nav>
                <nav className="toplink copyright">
                    <ul className="footernav copyrightnav">
                        <li className="footerlink copyrightlink">
                            <span>Copyright © 2022 | All rights reserved.</span>
                        </li>
                    </ul>

                </nav>
            </div>
        </footer>
    );
}

export default Footer
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage';
import Home from './components/Mainpage/Home';

import { QueryClient, QueryClientProvider } from 'react-query';
import DonarDetails from './components/DonationDetails';
import DonationForm from './components/Mainpage/DonationForm';
import LoginPage from './components/Mainpage/Admin/AdminLogin';

// Create a QueryClient instance
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<MainPage />}>
          <Route index element={<Home />} />
            <Route index path = 'Home' element={<Home />} />
            <Route path='donardetails' element={<DonarDetails />} />
            <Route path='DonateNow' element={<DonationForm />} />
            <Route path = 'Admin' element = {<LoginPage/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
